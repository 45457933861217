'use strict';

export default class SfFormDropdown {
  constructor(el) {
    this.el = el;
    this.selectedDropdownValue = null;
    this.dropdownVisible = false;
    this.onSelectedCallback = function() {};
    this.init();
  }

  init() {
    this.labelValueEl = this.el.querySelector('span');
    this.listWrapperEl = this.el.querySelector('.dropdown-list');

    if (window.extendedSearch && window.queryProjectType.value) {
      // Fills project type input's placeholder with query parameter 'parent_project_type_id'
      this.selectedDropdownValue = {
        value: window.queryProjectType.value,
        label: window.queryProjectType.label,
        translatedLabel: window.queryProjectType.translatedLabel
      };

      this.labelValueEl.textContent = this.selectedDropdownValue.translatedLabel;
      this.labelValueEl.className = 'has-value';
    }

    // Prevent the event to propagate when clicking in the list
    this.listWrapperEl.addEventListener('click', (event) => {
      event.stopPropagation();

      // If element that's inside the wrapper is a <li> item, trigger the onItemClicked fn
      if (event.target.tagName === 'LI') {
        this.onItemClicked(event.target);
      }
    });

    // Hide list on blur
    window.document.addEventListener('click', this.toggleDropdown.bind(this, false));

    // On field click, toggle hide/show list
    this.el.addEventListener('click', (event) => {
      event.stopPropagation();
      this.toggleDropdown();
    });
  }

  onItemClicked(el) {
    this.selectedDropdownValue = { label: el.dataset.label, translatedLabel: el.textContent, value: el.dataset.value };
    this.labelValueEl.textContent = this.selectedDropdownValue.translatedLabel;
    this.labelValueEl.className = 'has-value';

    this.toggleDropdown(false);
    this.onSelectedCallback();
  }

  toggleDropdown(value) {
    this.dropdownVisible = typeof value === 'boolean' ? value : !this.dropdownVisible;
    const action = this.dropdownVisible ? 'add' : 'remove';
    this.listWrapperEl.classList[action]('opened');
  }
}
