'use strict';

export default [{
  address: 'SoHo, New York, New York, United States',
  place_id: 'ChIJ8-JRXoxZwokRGPiQ9Ek0L84',
  term: 'SoHo',
  term_bold: 'SoHo',
  term_sub: 'New York'
}, {
  address: 'Manhattan, New York, New York, United States',
  place_id: 'ChIJYeZuBI9YwokRjMDs_IEyCwo',
  term: 'Manhattan',
  term_bold: 'Manhattan',
  term_sub: 'New York'
}, {
  address: 'Le Marais, Paris, France',
  place_id: 'ChIJ6UrOzQNu5kcRRp5vRIClzzg',
  term: 'Le Marais',
  term_bold: 'Le Marais',
  term_sub: 'Paris'
}, {
  address: '1er Arrondissement, Paris, France',
  place_id: 'ChIJPStI0CVu5kcRUBqUaMOCCwU',
  term: '1er Arrondissement',
  term_bold: '1er Arrondissement',
  term_sub: 'Paris'
}, {
  address: 'Shoreditch, London, United Kingdom',
  place_id: 'ChIJ_R7u-6QcdkgR_TvWQJZsm3k',
  term: 'Shoreditch',
  term_bold: 'Shoreditch',
  term_sub: 'London'
}];
