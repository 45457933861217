'use strict';

import SfFormLocation from './sf_form_location';
import SfFormDropdown from './sf_form_dropdown';

class SfSearchForm {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.projectTypesEl = this.el.querySelector('.sf-form-project-types');
    this.durationLengthEl = this.el.querySelector('.sf-form-duration-options');

    // Initialize the location field
    this.locationEl = this.el.querySelector('.sf-form-location');
    this.sfFormLocation = new SfFormLocation(this.locationEl); // eslint-disable-line

    // If the duration dom node exists, we initialize the duration field
    if (this.durationLengthEl) {
      new SfFormDropdown(this.durationLengthEl); // eslint-disable-line
    }

    // If the project type dom node exists, we initialize the project type field
    if (this.projectTypesEl) {
      this.sfFormProjectType = new SfFormDropdown(this.projectTypesEl); // eslint-disable-line
    } else {
      // We only want the form to submit automatically on address input when there's no project type field
      this.sfFormLocation.onSelected(this.submit.bind(this));
    }
  }

  submit() {
    const search = this.sfFormLocation.searcher.selectedResult;
    let dropdownValue = {};

    if (this.sfFormProjectType) {
      const selectedProjectType = this.sfFormProjectType.selectedDropdownValue;

      dropdownValue = {
        projectTypeLabel: (selectedProjectType && selectedProjectType.label) || undefined,
        projectTypeId: (selectedProjectType && selectedProjectType.value) || undefined
      };
    }

    const duration = _.get(this, 'sfFormDuration.selectedDropdownValue.value');

    analytics.track(
      'Hero Search Form Submitted',
      Object.assign(dropdownValue, {
        address: search.address,
        address_prefill: search.city,
      })
    );

    analytics.track('search', Object.assign(dropdownValue, {
      term: search.term,
      latitude: search.latitude,
      longitude: search.longitude
    }), {}, () => {
      const address = search.address || search.term || '';
      const order = 'search DESC';

      const queryParams = jQuery.param({
        address: address,
        latitude: search.latitude,
        longitude: search.longitude,
        lat_l: search.lat_l,
        lat_g: search.lat_g,
        lng_l: search.lng_l,
        lng_g: search.lng_g,
        order: order,
        country: search.country,
        city: search.city,
        zoom: 11,
        fit_bounds: true,
        parent_project_type_id: dropdownValue.projectTypeId,
        duration: duration
      });

      const pathPrefix = /^\/[a-z]{2}-[a-z]{2}\/.*/gi.test(window.location.pathname) ? '/' + window.location.pathname.split('/')[1] + '/' : '/';
      window.location.href = pathPrefix + 'search?' + queryParams.replace(/\+/g, '%20');
    });
  }
}

const init = () => {
  const formEl = document.querySelector('.sf-search-form');

  if (formEl) {
    const searchForm = new SfSearchForm(formEl); // eslint-disable-line

    // Fix for iOS devices not blurring search input when tapping outside of it
    if (/ipod|ipad|iphone/i.test(navigator.userAgent)) {
      const $input = jQuery(formEl).find('input');
      jQuery('body').on('touchstart', (e) => {
        if (!$input.is(e.target)) {
          $input.blur();
        }
      });
    }

    const submitBtnEl = formEl.querySelector('.submit-btn');

    if (submitBtnEl) {
      submitBtnEl.addEventListener('click', function () {
        searchForm.submit();
      });
    }

    const longTermDurationEl = formEl.querySelector('.duration-long-term');
    if (longTermDurationEl) {
      longTermDurationEl.addEventListener('click', () => {
        window.location.href = 'https://www.thestorefront.com/go/retail-rental-long-term/';
      });
    }
  }
};

const attemptInit = (attemptsLeft, nAttempts) => {
  if (window.angular) {
    init();
  } else if (attemptsLeft > 0) {
    nAttempts = nAttempts || 0;
    setTimeout(() => attemptInit((attemptsLeft - 1), (nAttempts + 1)), (500 + (nAttempts * 200)));
  }
};

if (document.readyState !== 'loading') {
  attemptInit(5);
} else {
  document.addEventListener('DOMContentLoaded', attemptInit);
}
