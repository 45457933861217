'use strict';

import { PlaceFinder } from './placefinder';

export default class SfFormLocation {
  constructor(el) {
    this.el = el;

    document.addEventListener('LayoutHydrated', () => {
      if (!window.mountedComponents || window.mountedComponents.indexOf('searchForm') !== -1) {
        window.mountedComponents = window.mountedComponents
          ? [...window.mountedComponents, 'searchForm']
          : ['searchForm'];

        window.angular.element(document).ready(() => {
          const $injector = window.angular.element(document.body).injector();
          const $translate = $injector.get('$translate');

          this.searcher = new PlaceFinder(true, $translate.instant('directives.navbar.search.dropdownHeader')); // eslint-disable-line
          this.init();
        });
      }
    });
  }

  init() {
    this.locationInputEl = this.el.querySelector('.sf-form-input');
    this.searcher.bindTo(this.locationInputEl);
  }

  onSelected(callback) {
    this.searcher.onPlaceChanged = callback;
  }
}
